import React, { useState } from "react";
import OtpInput from "react-otp-input";

import eye from "../../assets/img/Eye.svg";
import { Slide, ToastContainer } from "react-toastify";
import { getAllUserLoginDetails, patchverifyOtp, postOtp } from "../API/Api";
import { NavLink } from "react-router-dom";
const Register = (props) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userid, setUserid] = useState("");
  const [otpvalue, setOtpValue] = useState("");
  const [otp, setOtp] = useState("");
  const [warningMsg_UN, setWarningMsg_UN] = useState(false);
  const [warningMsg_UN_sp, setWarningMsg_UN_sp] = useState(false);
  const [error_msg, setError_Msg] = useState(false);
  const [warningMsg_E, setWarningMsg_E] = useState(false);
  const [warningMsg_p, setWarningMsg_p] = useState(false);
  const [warningMsg_le, setWarningMsg_le] = useState(false);
  const [warningMsg_Up, setWarningMsg_Up] = useState(false);
  const [warningMsg_lw, setWarningMsg_lw] = useState(false);
  const [warningMsg_spl, setWarningMsg_spl] = useState(false);
  const [warningMsg_num, setWarningMsg_num] = useState(false);
  const [warningOtp, setWarningOtp] = useState(false);
  const [warningMsg_Terms, setWarningMsg_Terms] = useState(false);
  const [erroStatus, setErrorStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  async function AuthRegister(e) {
    e.preventDefault();
    setErrorStatus(true);
    let format = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (username.trim() === "") {
      return setWarningMsg_UN(true);
    } else if (username.trim() !== "") {
      setWarningMsg_UN(false);
      if (format.test(username)) {
        return setWarningMsg_UN_sp(true);
      } else {
        setWarningMsg_UN_sp(false);
      }
    }

    if (password.trim() === "") {
      return setWarningMsg_p(true);
    } else {
      setWarningMsg_p(false);
      if (new RegExp("(?=.*[a-z])").test(password)) {
        setWarningMsg_lw(false);
      } else {
        return setWarningMsg_lw(true);
      }
      if (new RegExp("(?=.*[A-Z])").test(password.trim())) {
        setWarningMsg_Up(false);
      } else {
        return setWarningMsg_Up(true);
      }
      if (new RegExp("(?=.*[0-9])").test(password.trim())) {
        setWarningMsg_num(false);
      } else {
        return setWarningMsg_num(true);
      }
      if (new RegExp("(?=.*[@$!])").test(password.trim())) {
        setWarningMsg_spl(false);
      } else {
        return setWarningMsg_spl(true);
      }
      if (new RegExp("(?=.{8,})").test(password.trim())) {
        setWarningMsg_le(false);
      } else {
        return setWarningMsg_le(true);
      }
    }
    if (email.trim() === "") {
      return setWarningMsg_E(true);
    } else {
      setWarningMsg_E(false);
    }
    if (!isChecked) {
      return setWarningMsg_Terms(true);
    } else {
      setWarningMsg_Terms(false);
    }
    let register = {
      email: email,
      firstName: username,
      password: password,
      roles: ["consumer"],
      status: "inactive"
    };

    setErrorStatus(false);
    getAllUserLoginDetails().then((response) => {
      // console.log(response);
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].email === email.trim()) {
          setUserName("");
          setEmail("");
          setPassword("");
          setOtpValue("");
          setErrorStatus(true);
          return setError_Msg(true);
        }
      }
    });

    setError_Msg(false);
    setErrorStatus(false);

    let result = await postOtp(register).then(
      (res) => res,
      setErrorStatus(false)
    );
    result = await result.json();
    // let result = postOtp(register);
    // result = await result.json();
    console.log(result);

    if (result.otp !== undefined) {
      setErrorStatus(false);
      setOtpValue(result.otp);
      setUserid(result.id);
    }

    if (result.otp !== undefined) {
      setErrorStatus(false);
    }

    console.log(result.otp);
  }

  function VerifyOtp(e) {
    setErrorStatus(false);
    e.preventDefault();
    // eslint-disable-next-line
    if (otpvalue === otp) {
      patchverifyOtp(userid).then((response) => {
        setUserName("");
        setEmail("");
        setPassword("");
        setOtpValue("");

        props.showToast3();
        props.navLogin();
      });
    } else {
      setErrorStatus(true);
      if (otp !== "") {
        setWarningOtp(true);
      }
    }
  }

  return (
    <>
      {otpvalue === undefined || otpvalue === "" ? (
        <form onSubmit={AuthRegister}>
          <div className="form__group">
            <label>Username</label>
            <input
              type="text"
              spellCheck="false"
              className="form__control"
              placeholder="User Name"
              value={username}
              onChange={(e) =>
                e.target.value !== ""
                  ? setUserName(e.target.value)
                  : setUserName(e.target.value)
              }
            />
          </div>
          <div className="form__group showPassword_input">
            <label>Password</label>
            <input
              type={showPassword ? "text" : "password"}
              className="form__control"
              placeholder="Password"
              value={password}
              spellCheck="false"
              onChange={(e) =>
                e.target.value !== ""
                  ? setPassword(e.target.value)
                  : setPassword(e.target.value)
              }
            />
            <span onClick={handleShowPassword} className="showPassword">
              <img src={eye} alt="eye" />
            </span>
          </div>
          <div className="form__group">
            <label>Email</label>
            <input
              type="email"
              className="form__control"
              placeholder="Email@gmail.com"
              spellCheck="false"
              value={email}
              onChange={(e) =>
                e.target.value !== ""
                  ? setEmail(e.target.value)
                  : setEmail(e.target.value)
              }
            />
          </div>
          <div className="form__group" style={{ display: "flex" }}>
            <input
              type="checkbox"
              style={{ cursor: "pointer" }}
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
            <p
              style={{
                paddingLeft: "6px",
                marginTop: "-3px",
                marginBottom: "0",
                color: "#AFAFAF"
              }}
            >
              By clicking Sign Up, you are indicating that you have read and
              acknowledge the{" "}
              <NavLink to="/privacy" style={{ color: "#666565" }}>
                Terms of Service
              </NavLink>{" "}
              and{" "}
              <NavLink to="/privacy" style={{ color: "#666565" }}>
                Privacy Notice
              </NavLink>{" "}
              .
            </p>
          </div>
          <button className="btn btn-primary">
            <span>Sign Up</span>
          </button>
          <ToastContainer
            limit={1}
            transition={Slide}
            style={{ fontSize: "16px" }}
          />
          <div className={erroStatus ? "error_wrapper" : ""}>
            <div
              style={
                warningMsg_UN === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *UserName is required
            </div>
            <div
              style={
                warningMsg_UN_sp === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              UserName Should not contain special symbols
            </div>
            <div
              style={
                warningMsg_p === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *password is required
            </div>
            <div
              style={
                warningMsg_le === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *password Length Should be 8 to 20 characters only
            </div>
            <div
              style={
                warningMsg_spl === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *password Must contain any of the following symbols [@$!]
            </div>
            <div
              style={
                warningMsg_num === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *password Must have one number in between[0-9]
            </div>
            <div
              style={
                warningMsg_Up === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *password Must contain at least one upper-case
            </div>
            <div
              style={
                warningMsg_lw === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *password Must contain at least one lower-case
            </div>
            <div
              style={
                warningMsg_E === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *Email is required
            </div>
            <div
              style={
                warningMsg_Terms === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              Please select Checkbox
            </div>
            <div
              style={
                error_msg === false ? { display: "none" } : { display: "block" }
              }
            >
              User already exist
            </div>
          </div>
        </form>
      ) : (
        <></>
      )}
      {otpvalue !== undefined && otpvalue.length > 4 && (
        <form onSubmit={VerifyOtp} className="otpform">
          <div className="otp-notes">
            <h6>
              Please enter the 5 digit verification code sent to your register
              mail id
            </h6>
          </div>
          <div className="form__group">
            <label>Enter Otp</label>
            <OtpInput value={otp} onChange={setOtp} numInputs={5} autoFocus />
          </div>

          <button className="btn btn-primary">
            <span>Verify Otp</span>
          </button>
          <div className={erroStatus ? "error_wrapper" : ""}>
            <div
              style={
                warningOtp === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              please Check OTP
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default Register;
