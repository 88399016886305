import React from "react";
import lozad from "lozad";
import videojs from "video.js";
import muteicon from "../../assets/img/mute.svg";
import unmuteicon from "../../assets/img/unmute.svg";
const config = {
  controls: false,
  autoPlay: false,
  loop: true,
  preload: "none",
  techOrder: ["html5"],
  children: {
    mediaLoader: true,
    posterImage: true,
    controlBar: {
      children: []
    }
  },

  html5: {
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    vhs: {
      overrideNative: false
    }
  }
};

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.observer = lozad();
  }
  state = {
    muted: true
  };

  componentDidMount() {
    this.observer.observe();
    this.player = videojs(this.videoEl1, config);
    window.player = this.player;
    this.player.src({
      src: this.props.source,
      type: this.props.type
    });
    this.player.on("canplaythrough", () => {
      this.player.muted(this.state.muted);
    });
  }

  componentDidUpdate(prevProps) {
    const { muted } = this.state;
    if (muted !== prevProps) {
      this.player.muted(muted);
    }
  }

  toggleMuted = () => {
    console.log(this.state.muted);
    this.setState({
      muted: !this.state.muted
    });
  };

  stopMovie = (e) => {
    e.target.pause();
    e.target.currentTime = 0;
  };

  playMovie = (e) => {
    e.target.play();
  };

  render() {
    return (
      <div className="video-player">
        <video
          ref={(el) => (this.videoEl1 = el)}
          data-vjs-player
          className="lozad"
          data-poster={this.props.poster}
          playsInline={true}
          webkit-playsinline="true"
          onMouseOver={this.playMovie}
          onMouseOut={this.stopMovie}
          onLoadStart={() => {
            this.setState({ isLoading: true });
          }}
          onLoadedData={() => {
            this.setState({ isLoading: false });
          }}
        />

        <button
          className="bannerCard__mute"
          onClick={this.toggleMuted}
          style={{ display: this.props.dis ? "block" : "none" }}
        >
          <span className="mute">
            <img
              className="lozad"
              src={this.state.muted ? muteicon : unmuteicon}
              alt="mute"
            />
          </span>
        </button>
      </div>
    );
  }
}
export default VideoPlayer;
