import React, { useState } from "react";
// import axios from "axios";
import { setUserSession } from "../utlis";
import "../../assets/styles/common/notification.css";
import eye from "../../assets/img/Eye.svg";
import { Slide, ToastContainer } from "react-toastify";
import {
  getAllUserLoginDetails,
  postUserLoginDetails,
  postResetPassword
} from "../API/Api";
const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [warningMsg_login, setWarningMsg_login] = useState(false);
  const [warningMsg_pE, setWarningMsg_pE] = useState(false);
  const [warningMsg_pp, setWarningMsg_pp] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [warningMsg_pFE, setWarningMsg_pFE] = useState(false);
  const [Femail, setFEmail] = useState("");
  const [warningMsg_rps, setWarningMsg_rps] = useState(false);
  const [erroStatus, setErrorStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function AuthLogin(e) {
    e.preventDefault();
    setError(null);
    setLoading(true);
    setWarningMsg_login(false);
    if (email === "") {
      setErrorStatus(true);
      setLoading(false);
      setErrorStatus(true);
      setWarningMsg_pE(true);
    } else {
      setErrorStatus(false);
      setWarningMsg_pE(false);
    }
    if (password === "") {
      setErrorStatus(true);
      setLoading(false);
      return setWarningMsg_pp(true);
    } else {
      setErrorStatus(false);
      setWarningMsg_pp(false);
    }

    const obj = {
      email: email.trim(),
      password: password.trim()
    };
    postUserLoginDetails(obj)
      .then((response) => {
        setUserSession(response.data.token);
        if (response.status === 200) {
          getAllUserLoginDetails().then((response) => {
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].email === email.trim()) {
                setErrorStatus(true);
                localStorage.setItem("userName", response.data[i].firstName);
                setLoading(false);
                localStorage.setItem("userId", response.data[i].id);
                const v = [];
                localStorage.setItem("wishList", JSON.stringify(v));
                props.closeLogin();
                window.location.reload();
              }
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(password);
        setWarningMsg_pp(false);
        setWarningMsg_pE(false);
        setErrorStatus(true);
        setWarningMsg_login(true);
        setLoading(false);
      });
  }
  function handleForgetPassword() {
    setErrorStatus(false);
    setForgetPassword(true);
  }
  function RestPassword(e) {
    e.preventDefault();
    if (Femail === "") {
      setErrorStatus(true);
      return setWarningMsg_pFE(true);
    } else {
      setErrorStatus(false);
      setWarningMsg_pFE(false);
    }
    console.log("login");
    const obj = {
      email: Femail.trim(),
      client_api_url: window.location.host
    };
    setErrorStatus(false);
    setLoading(true);
    postResetPassword(obj)
      .then((response) => {
        // console.log(response);
        setErrorStatus(true);
        setWarningMsg_rps(true);
        setLoading(false);
        setErrorStatus(false);
        // props.showToat();
        props.showToast2();
        props.closeLogin();
      })
      .catch((error) => {
        setErrorStatus(false);
        props.navRegister();
        setFEmail("");
        setForgetPassword(false);
        setLoading(false);
        // setToastOPen(true);
        props.showToast1();
        props.navRegister();
      });
  }
  return (
    <>
      {forgetPassword === false && (
        <form onSubmit={AuthLogin}>
          <div className="form__group">
            <label>Email</label>
            <input
              type="Email"
              className="form__control"
              spellCheck="false"
              placeholder="Email"
              value={email}
              onChange={(e) =>
                e.target.value !== ""
                  ? setEmail(e.target.value)
                  : setEmail(e.target.value)
              }
            />
          </div>
          <div className="form__group showPassword_input">
            <label>Password</label>
            <input
              type={showPassword ? "text" : "password"}
              spellCheck="false"
              className="form__control"
              placeholder="Password"
              value={password}
              onChange={(e) =>
                e.target.value !== ""
                  ? setPassword(e.target.value)
                  : setPassword(e.target.value)
              }
            />
            <span onClick={handleShowPassword} className="showPassword">
              <img src={eye} alt="eye" />
            </span>

            <span className="forget-password" onClick={handleForgetPassword}>
              Forgot Password ?
            </span>
            {/* {error && <div className="error">{error}</div>} */}
          </div>
          <button className="btn btn-primary" disabled={loading}>
            {loading ? "loading.." : "Login"}
          </button>

          <div className={erroStatus ? "error_wrapper" : ""}>
            <div
              style={
                warningMsg_login === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              invalid user! please check email and password
            </div>
            <div
              style={
                warningMsg_pE === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *Email is Required
            </div>
            <div
              style={
                warningMsg_pp === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *Password is Required
            </div>
            {error && <div className="error">{error}</div>}
          </div>
        </form>
      )}
      {forgetPassword === true && (
        <form onSubmit={RestPassword}>
          <div className="form__group">
            <label>Enter the Email to reset your password</label>
          </div>
          <div className="form__group">
            <label>Email</label>
            <input
              type="Email"
              className="form__control"
              placeholder="Email"
              spellCheck="false"
              value={Femail}
              onChange={(e) =>
                e.target.value !== ""
                  ? setFEmail(e.target.value)
                  : setFEmail(e.target.value)
              }
            />
          </div>
          <button className="btn btn-primary">
            {loading ? "loading.." : "Reset Password"}
          </button>
          <ToastContainer limit={1} transition={Slide} />
          <div className={erroStatus ? "error_wrapper" : ""}>
            <div
              style={
                warningMsg_pFE === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *Email is Required
            </div>
            <div
              style={
                warningMsg_rps === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              *Please Check Your Email for setting New Password
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default Login;
