import React from "react";

//image
import logoIcon from "../../assets/img/logo.svg";
import emailIcon from "../../assets/img/email.svg";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="cloudicon-wrapper">
          <span className="moon-icon sm pos-first"></span>
          <span className="capsule-icon pos-second"></span>
          <span className="moon-icon sm pos-third"></span>
          <span className="capsule-icon pos-fourth"></span>
          <span className="halfmoon-icon pos-five"></span>
        </div>
        <div className="container">
          <div className="flex-between m-column">
            <div className="footer__logo">
              <img src={logoIcon} alt="logo" />
              <span>Live Commerce Platform</span>
              <a href="mailto:hello@degpeg.com" className="footer__email">
                <img src={emailIcon} alt="email" />
                Email - hello@degpeg.com
              </a>
            </div>
            <div className="footer__sociallinks">
              <h5>Quick Links</h5>
              {/* <a href="/">Home</a>
              <a href="/category">Categories</a>
              <a href="/liveshows">Live Shows</a>
              <a href="/calendar">Calender</a> */}
              <NavLink exact to={{ pathname: "/" }}>
                Home
              </NavLink>
              <NavLink exact to={{ pathname: "/category" }}>
                Categories
              </NavLink>
              <NavLink exact to={{ pathname: "/liveshows" }}>
                Live Shows
              </NavLink>
              <NavLink exact to={{ pathname: "/calendar" }}>
                Calender
              </NavLink>
            </div>
            <div className="footer__sociallinks">
              <h5>Social Links</h5>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
              <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Youtube
              </a>
              <a
                href="https://mobile.twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyrights">
        <div className="container flex-between">
          <span>Copyright 2021. All Rights Reserved.</span>
          <ul>
            <li>
              <a href="/privacy"> Privacy Policy </a>
            </li>
            <li>
              <a href="/privacy"> Terms & Conditions </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
