import React, { useState } from "react";
import Modal from "react-modal";
import Login from "./Login";
import Register from "./Register";
// images
import logoIcon from "../../assets/img/login_logo.svg";
import loginImg from "../../assets/img/loginimg.png";
import signupImg from "../../assets/img/signup.png";
import { Slide, ToastContainer } from "react-toastify";

const LoginModal = ({
  modalOpen,
  closeModal,
  showToat,
  showToast1,
  showToast2,
  showToast3
}) => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  function navToggleTab() {
    toggleTab(1);
  }
  function navRegisteToggleTab() {
    toggleTab(2);
  }
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      className={`modal-wrapper auth ${
        toggleState === 1 ? "signin" : "signup"
      }`}
      ariaHideApp={false}
    >
      <button type="submit" className="modal-closeIcon" onClick={closeModal}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path
            d="M10.88 8.99996L16.6134 3.27996C16.8644 3.02889 17.0055 2.68836 17.0055 2.3333C17.0055 1.97823 16.8644 1.6377 16.6134 1.38663C16.3623 1.13556 16.0218 0.994507 15.6667 0.994507C15.3116 0.994507 14.9711 1.13556 14.72 1.38663L9.00004 7.11996L3.28004 1.38663C3.02897 1.13556 2.68844 0.994507 2.33337 0.994507C1.97831 0.994507 1.63778 1.13556 1.38671 1.38663C1.13564 1.6377 0.994585 1.97823 0.994585 2.3333C0.994585 2.68836 1.13564 3.02889 1.38671 3.27996L7.12004 8.99996L1.38671 14.72C1.26174 14.8439 1.16254 14.9914 1.09485 15.1539C1.02716 15.3163 0.99231 15.4906 0.99231 15.6666C0.99231 15.8426 1.02716 16.0169 1.09485 16.1794C1.16254 16.3419 1.26174 16.4893 1.38671 16.6133C1.51066 16.7383 1.65813 16.8375 1.8206 16.9052C1.98308 16.9728 2.15736 17.0077 2.33337 17.0077C2.50939 17.0077 2.68366 16.9728 2.84614 16.9052C3.00862 16.8375 3.15609 16.7383 3.28004 16.6133L9.00004 10.88L14.72 16.6133C14.844 16.7383 14.9915 16.8375 15.1539 16.9052C15.3164 16.9728 15.4907 17.0077 15.6667 17.0077C15.8427 17.0077 16.017 16.9728 16.1795 16.9052C16.342 16.8375 16.4894 16.7383 16.6134 16.6133C16.7383 16.4893 16.8375 16.3419 16.9052 16.1794C16.9729 16.0169 17.0078 15.8426 17.0078 15.6666C17.0078 15.4906 16.9729 15.3163 16.9052 15.1539C16.8375 14.9914 16.7383 14.8439 16.6134 14.72L10.88 8.99996Z"
            fill="black"
          />
        </svg>
      </button>
      <div className="auth__left">
        <div className="auth__logo">
          <img src={logoIcon} alt="logo" />
          <span>Live Commerce Platform</span>
        </div>
        <div className="auth__icon">
          {/* signin */}
          <img src={loginImg} alt="icon" className="signin-img" />
          {/* signup */}
          <img src={signupImg} alt="icon" className="signup-img" />
        </div>
      </div>
      <div className="auth__right">
        {/* signin */}
        <div className="auth__signin">
          <h5>Join Degpeg Today</h5>
          <div className="auth__tabs">
            <ul>
              <li
                className={toggleState === 1 ? "active" : ""}
                onClick={() => toggleTab(1)}
              >
                Login
              </li>
              <li
                className={toggleState === 2 ? "active" : ""}
                onClick={() => toggleTab(2)}
              >
                Sign Up
              </li>
            </ul>
          </div>
          <ToastContainer
            limit={1}
            transition={Slide}
            style={{ fontSize: "16px" }}
          />
          <Login
            closeLogin={closeModal}
            navRegister={navRegisteToggleTab}
            showToat={showToat}
            showToast1={showToast1}
            showToast2={showToast2}
          />
          <span className="notamember">
            Not a Member ? <span onClick={() => toggleTab(2)}> Sign Up </span>
          </span>
        </div>
        {/* signup */}
        <div className="auth__signup">
          <h5>Signup Degpeg Today</h5>
          <div className="auth__tabs">
            <ul>
              <li
                className={toggleState === 1 ? "active" : ""}
                onClick={() => toggleTab(1)}
              >
                Login
              </li>
              <li
                className={toggleState === 2 ? "active" : ""}
                onClick={() => toggleTab(2)}
              >
                Sign Up
              </li>
            </ul>
          </div>
          <Register navLogin={navToggleTab} showToast3={showToast3} />
          <span className="notamember">
            Already have account ?{" "}
            <span onClick={() => toggleTab(1)}> Login </span>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
