import React, { Component } from "react";

import date from "date-and-time";
import {
  getSesseionChatMessages,
  postSessionChatMessages,
  socketConnection
} from "../../API/Api";
import sentIcon from "../../../assets/img/newTemplate/send.svg";
import chatIcon from "../../../assets/img/newTemplate/chat.svg";

export default class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      message: "",
      show_message: "",
      chat: [],
      isChatVisible: false,
      userName:
        localStorage.getItem("userName") === null
          ? ""
          : localStorage.getItem("userName"),
      userId: localStorage.getItem("userName"),
      socket: null
    };
    this.publish = this.publish.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.scroll = this.scroll.bind(this);
  }
  componentDidMount() {
    getSesseionChatMessages(this.props.session_id).then((result) => {
      console.log("chat", result);

      this.setState({ chat: result });
    });
    console.log(this.props.session_id);
    if (this.props.status === "live") {
      const newSocket = socketConnection();
      this.setState({ socket: newSocket });

      var msg = {
        room: this.props.session_id
      };
      console.log("socket connected", newSocket);
      newSocket.emit("join", msg);

      console.log("before live", newSocket);
      console.log("session Id", this.props.session_id);
      if (this.props.status === "live" || this.props.session_id) {
        newSocket.on("chat_message", this.messageListener);
      }
      console.log("socket connection On");
      return () => newSocket.close();
    }
  }
  // destroy player on unmount
  componentWillUnmount() {
    const { socket } = this.state;
    console.group(socket);
    if (this.player) {
      this.player.dispose();
    }
    if (this.props.status === "live" && socket) {
      socket.off("chat_message", this.messageListener);
    }
  }
  messageListener = (message) => {
    console.log("Message", message);
    const newMessages = {};
    newMessages["userName"] = message.userId;
    newMessages["message"] = message.message;
    console.log(newMessages);
    this.setState({ chat: [...this.state.chat, newMessages] });
    this.scroll();
  };

  shareListener = (share) => {
    console.log("shares", share);
  };
  handleChange({ target }) {
    this.setState(() => ({
      message: target.value
    }));
  }
  handleCloseModal() {
    this.setState({ showModal: false });
    if (localStorage.getItem("userName")) {
      this.setState({ userName: localStorage.getItem("userName") });
    }
  }
  scroll() {
    setTimeout(() => {
      var len = document.querySelector(".live__chat__body").lastElementChild;

      if (len != null) {
        len.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }, 400);
  }
  togglechat = () => {
    this.setState((prevState) => ({ isChatVisible: !prevState.isChatVisible }));
  };
  publish(e) {
    if (this.state.userName) {
      e.preventDefault();
      const now = new Date();
      const dt = date.format(now, "YYYY-MM-DDTHH:mm:ss.msZ");
      const { socket, message } = this.state;
      var msg = {
        time_stamp: dt,
        userName: this.state.userName,
        userId: this.state.userId,
        message: message,
        session_id: this.props.session_id
        // device_id: "web",
        // channelId: "channel",
      };
      var postMsg = {
        time_stamp: dt,
        userName: this.state.userName,
        userId: this.state.userName,
        message: message,
        liveSessionId: this.props.session_id
      };
      msg = JSON.stringify(msg);

      postSessionChatMessages(postMsg).then((res) => {
        console.log(res);
        getSesseionChatMessages(this.props.session_id).then((result) => {
          console.log(result);
          this.setState({ chat: result });
          console.log(result);
          if (this.props.status === "live") {
            console.log(socket);
            socket.emit("chat_message", msg);
          }
          this.scroll();
        });
      });
      console.log("message", msg);
      this.setState({ message: "" });
    } else {
      this.setState(() => ({
        message: "",
        showModal: true
      }));
      const s = window.prompt("Please enter your name");
      console.log(s);
      this.setState({ userName: s });
      this.setState({ userId: s });
      localStorage.setItem("userName", s);
    }
  }
  render() {
    const { isChatVisible } = this.state;

    return (
      <div
        className={`live__chat ${isChatVisible ? "isclose " : " "} ${
          this.props.landScape === 2 ? "active" : "inactive"
        } ${this.props.minimize ? "is_minimized" : " "}`}
      >
        <div className="live__chat__header" onClick={this.togglechat}>
          <h5>
            Chat
            <span className="m-hide btn-chat">
              <img src={chatIcon} alt="chat" />
              <span className="live_count">10</span>
            </span>
          </h5>
          <svg width="16" height="10" viewBox="0 0 16 10" fill="none">
            <path
              d="M16 1.55392C16.0009 1.70497 15.9719 1.85471 15.9148 1.99454C15.8577 2.13438 15.7735 2.26157 15.6671 2.36881L8.78071 9.25524C8.56567 9.469 8.27477 9.58899 7.97156 9.58899C7.66834 9.58899 7.37745 9.469 7.1624 9.25524L0.275982 2.36881C0.0879519 2.14925 -0.0103014 1.86682 0.000855961 1.57796C0.0120133 1.2891 0.131759 1.01509 0.336165 0.810688C0.54057 0.606282 0.81458 0.486535 1.10344 0.475378C1.3923 0.46422 1.67473 0.562474 1.89429 0.750504L7.96582 6.82203L14.0374 0.750504C14.1972 0.589337 14.4012 0.479187 14.6237 0.433983C14.8461 0.38878 15.077 0.410552 15.2871 0.496547C15.4971 0.582542 15.677 0.728897 15.8039 0.917107C15.9308 1.10532 15.999 1.32693 16 1.55392V1.55392Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="live__chat__body">
          {/* msg */}
          {this.state.chat &&
            [...Object.values(this.state.chat)]
              .sort((a, b) => a.time - b.time)
              .map((payload, index) =>
                payload.userName !== localStorage.getItem("userName") ? (
                  <div
                    className="chat-message receive"
                    key={payload.id}
                    id={payload.id}
                  >
                    <div className="chat-message-msg">
                      <div className="chat-avatar">
                        <span>
                          {payload.userName
                            ? payload.userName.split("@")[0]
                            : ""}
                        </span>
                      </div>
                      <h4>{payload.message}</h4>
                    </div>
                  </div>
                ) : (
                  <div
                    className="chat-message sent"
                    key={payload.id}
                    id={payload.id}
                  >
                    <div className="chat-message-msg">
                      <div className="chat-avatar">
                        <span>
                          {payload.userName
                            ? payload.userName.toUpperCase()
                            : ""}
                        </span>
                      </div>
                      <h4>{payload.message}</h4>
                    </div>
                  </div>
                )
              )}
        </div>
        <div className="live__chat__footer">
          <form>
            <input
              placeholder="Send Message"
              value={this.state.message}
              onChange={this.handleChange}
              required
            />
            <button onClick={this.publish}>
              <img src={sentIcon} alt="sent" />
            </button>
          </form>
        </div>
      </div>
    );
  }
}
