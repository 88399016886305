import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
// import profile from "../../assets/img/profile.svg";
import { useState } from "react";
import { useEffect, useRef } from "react";
import {
  changeUserDetails,
  getUserLoginDetails,
  patchChangePassword,
  postUserLoginDetails,
  uploadFile
} from "../API/Api";
import profileicon from "../../assets/img/user.svg";
import edit from "../../assets/img/edit.svg";

// import arrowRight from "../../assets/img/arrow-right.svg";
import "./Profile.css";

import eye from "../../assets/img/Eye.svg";
import { Slide, toast, ToastContainer } from "react-toastify";
// import { uploadFile } from "react-s3";
// import AWS from "aws-sdk";
//Aws
// const S3_BUCKET = "degpeg-file-storage";
// const REGION = "ap-south-1";
// const ACCESS_KEY = "AKIAX3JPB7XV5JT2726E";
// const SECRET_ACCESS_KEY = "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";
// const config = {
//   bucketName: S3_BUCKET,
//   region: REGION,
//   accessKeyId: ACCESS_KEY,
//   secretAccessKey: SECRET_ACCESS_KEY
// };

// AWS.config.update({
//   accessKeyId: "AKIAX3JPB7XV5JT2726E",
//   secretAccessKey: "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"
// });

// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION
// });
function Profile() {
  const [userName, setUserName] = useState(localStorage.getItem("userName"));
  const [email, setEmail] = useState("");
  const [editName, setEditName] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const textInput = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordBox, setPasswordBox] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);

  const [warningMsg_op, setWarningMsg_op] = useState(false);
  // const [warningMsg_p, setWarningMsg_p] = useState(false);
  const [warningMsg_np, setWarningMsg_np] = useState(false);
  const [warningMsg_le, setWarningMsg_le] = useState(false);
  const [warningMsg_Up, setWarningMsg_Up] = useState(false);
  const [warningMsg_lw, setWarningMsg_lw] = useState(false);
  const [warningMsg_spl, setWarningMsg_spl] = useState(false);
  const [warningMsg_num, setWarningMsg_num] = useState(false);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const [warningMsg_login, setWarningMsg_login] = useState(false);

  useEffect(() => {
    // console.log(imgUrl);
    const userId = localStorage.getItem("userId");
    getUserLoginDetails(userId).then((res) => {
      // console.log(res);
      setUserName(res.data.firstName);
      setEmail(res.data.email);
      if (res.data.avatar) {
        setImgUrl(res.data.avatar);
        // localStorage.setItem("profileImg", JSON.stringify(res.data.avatar));
      } else {
        setImgUrl(profileicon);
      }
    });

    // console.log(userDetails);
  }, []);
  const handleUpdateprofile = (e) => {
    e.preventDefault();
    setLoading(true);
    // const userId = localStorage.getItem("userId");
    // if (userId) {
    //   const obj = {
    //     firstName: userName,
    //     avatar: `${imgUrl}`
    //   };
    // console.log(obj);
    // changeUserDetails(userId, obj).then((res) => {
    // localStorage.setItem("userName", userName);
    // localStorage.setItem("profileImg", JSON.stringify(imgUrl));
    // window.location.reload();
    // });
    // }
    console.log(selectedFile);
    // const file = `users-dp/${
    //   new Date().toISOString() +
    //   "_" +
    //   localStorage.getItem("userId") +
    //   "_" +
    //   selectedFile.name
    // }`;
    // console.log(file);
    // const params = {
    //   ACL: "public-read",
    //   Body: selectedFile,
    //   Bucket: S3_BUCKET,
    //   Key: file,
    //   ContentType: selectedFile.type
    // };
    // myBucket
    //   .upload(params)
    //   .on("httpUploadProgress", function (evt) {
    //     console.log(evt.loaded + " of " + evt.total + " Bytes");
    //   })
    //   .send(function (err, data) {
    //     if (err) {
    //       console.log("There was an error uploading your file: ", err);
    //       return false;
    //     }
    //     console.log("Successfully uploaded file.", data);
    //     return true;
    //   });
    // myBucket.upload(params, function (err, data) {
    //   if (err) {
    //     console.log("There was an error uploading your file: ", err);
    //     // reject(err);
    //     //return false;
    //   } else {
    //     console.log("Successfully uploaded file.", data);
    //     let location = data.Location.split("south-1.amazonaws.com")[1];
    //     data.Location = "https://d3ur2dp0uh3ite.cloudfront.net" + location;
    //     console.log(location);
    //     console.log(data);
    //     // resolve(data);
    //     //return true;

    //     const obj = {
    //       firstName: userName,
    //       avatar: data
    //     };

    //     changeUserDetails(localStorage.getItem("userId"), obj).then((res) =>
    //       console.log(res)
    //     );
    //   }
    // });
    if (selectedFile) {
      uploadFile(selectedFile, localStorage.getItem("userId")).then((res) => {
        console.log(res);
        const obj = {
          firstName: userName,
          avatar: res.Location
        };

        changeUserDetails(localStorage.getItem("userId"), obj).then((res) => {
          getUserLoginDetails(localStorage.getItem("userId")).then((item) => {
            setImgUrl(item.data.avatar);
            setLoading(false);
            setSelectedFile(null);
            setUserName(item.firstName);
            window.location.reload();
          });
        });
      });
    } else {
      const obj = { firstName: userName };
      changeUserDetails(localStorage.getItem("userId"), obj).then((res) => {
        getUserLoginDetails(localStorage.getItem("userId")).then((item) => {
          setLoading(false);
          setUserName(item.firstName);
          window.location.reload();
        });
      });
    }
    // uploadFile(file, config)
    //   .then((data) => console.log(data))
    //   .catch((err) => console.error(err));
  };
  const editNameFunction = () => {
    setEditName(!editName);
    textInput.current.focus();
  };
  // const uploadtoAws = (e) => {
  //   e.preventDefault();
  //   console.log(e.target.files[0]);
  //   // setSelectedFile(e.target.files[0]);
  // };
  const closeModal = () => {
    setPasswordBox(false);
    setOldPassword("");
    setNewPassword("");
    setErrorStatus(false);
  };
  const handlePassword = (e) => {
    e.preventDefault();
    console.log(oldPassword, newPassword, email);
    if (oldPassword === "") {
      setErrorStatus(true);
      return setWarningMsg_op(true);
    } else {
      setWarningMsg_op(false);
      setErrorStatus(false);
    }
    if (newPassword === "") {
      setErrorStatus(true);
      return setWarningMsg_np(true);
    } else {
      setWarningMsg_np(false);
      setErrorStatus(false);
    }
    const obj = {
      email: email,
      password: oldPassword.trim()
    };
    postUserLoginDetails(obj)
      .then((res) => {
        setWarningMsg_login(false);
        console.log(res.data);
        setErrorStatus(true);
        // if (newPassword === "") {
        //   return setWarningMsg_p(true);
        // } else {
        //   setWarningMsg_p(false);
        // }
        if (new RegExp("(?=.*[a-z])").test(newPassword)) {
          setWarningMsg_lw(false);
        } else {
          return setWarningMsg_lw(true);
        }
        if (new RegExp("(?=.*[A-Z])").test(newPassword.trim())) {
          setWarningMsg_Up(false);
        } else {
          return setWarningMsg_Up(true);
        }
        if (new RegExp("(?=.*[0-9])").test(newPassword.trim())) {
          setWarningMsg_num(false);
        } else {
          return setWarningMsg_num(true);
        }
        if (new RegExp("(?=.*[@$!])").test(newPassword.trim())) {
          setWarningMsg_spl(false);
        } else {
          return setWarningMsg_spl(true);
        }
        if (new RegExp("(?=.{8,})").test(newPassword.trim())) {
          setWarningMsg_le(false);
          setErrorStatus(false);
          const newObj = {
            userId: localStorage.getItem("userId"),
            password: `${newPassword.trim()}`
          };
          patchChangePassword(localStorage.getItem("userId"), newObj).then(
            (response) => {
              console.log("sucess");
              showSucessToast();
              closeModal();
              setOldPassword("");
              setNewPassword("");
            }
          );
        } else {
          return setWarningMsg_le(true);
        }
      })
      .catch((err) => {
        console.log("n");
        setErrorStatus(true);
        setWarningMsg_login(true);
      });
  };
  const showSucessToast = () => {
    toast.success("Password Changed Successfully!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000
    });
  };
  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  return (
    <div className="home">
      <Header />
      {!passwordBox ? (
        <form className="profile_wrapper" onSubmit={handleUpdateprofile}>
          <div className="profile_container">
            <ToastContainer
              limit={1}
              transition={Slide}
              style={{ fontSize: "16px" }}
            />
            <div className="profile_image">
              <img src={imgUrl ? imgUrl : profileicon} alt="profile" />
              <input
                type="file"
                placeholder="select Image"
                className="profile_image_change"
                onChange={
                  // uploadtoAws
                  (e) => {
                    setSelectedFile(e.target.files[0]);
                    setImgUrl(URL.createObjectURL(e.target.files[0]));
                  }
                }
              ></input>
            </div>
            <div className="profile_input">
              <label>Email</label>
              <input
                type="email"
                className="form__control"
                spellCheck="false"
                disabled
                value={email}
                style={{ color: "#535353" }}
              />
            </div>
            <div className="profile_input">
              <label>User Name</label>
              <input
                type="text"
                className="form__control"
                spellCheck="false"
                value={userName}
                style={{ color: editName ? "#000" : "#535353" }}
                ref={textInput}
                onChange={(e) =>
                  e.target.value !== ""
                    ? setUserName(e.target.value)
                    : setUserName(e.target.value)
                }
              />
              <span className="profile_edit_icon" onClick={editNameFunction}>
                <img src={edit} alt="edit" />
              </span>
            </div>
            <div className="profile_change_password">
              <div
                className="profile_change_password_text"
                onClick={() => setPasswordBox(!passwordBox)}
              >
                Change Password
                {/* <img
                  src={edit}
                  alt="show"
                  style={{
                    color: "#ccc",
                    cursor: "pointer",
                    position: "absolute",
                    right: "15px",
                    top: "15px"
                  }}
                /> */}
                <span
                  className="profile_edit_icon_password"
                  onClick={editNameFunction}
                >
                  <img src={edit} alt="edit" />
                </span>
              </div>
            </div>
            {/* {passwordBox && (
            <div>
              <input
                type="password"
                className="form__control"
                spellCheck="false"
                placeholder="Enter New Password"
                value={password}
                // style={{ color: editName ? "#000" : "#535353" }}
                // ref={textInput}
                onChange={(e) =>
                  e.target.value !== ""
                    ? setPassword(e.target.value)
                    : setPassword("")
                }
              />
            </div>
          )} */}

            <div className="profile_update_button">
              <button>{loading ? "uploading..." : "Update"}</button>
            </div>
          </div>
        </form>
      ) : (
        <form className="profile_wrapper" onSubmit={handlePassword}>
          <div className="profile_container" style={{ position: "relative" }}>
            <span
              type="submit"
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                cursor: "pointer"
              }}
              onClick={closeModal}
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M10.88 8.99996L16.6134 3.27996C16.8644 3.02889 17.0055 2.68836 17.0055 2.3333C17.0055 1.97823 16.8644 1.6377 16.6134 1.38663C16.3623 1.13556 16.0218 0.994507 15.6667 0.994507C15.3116 0.994507 14.9711 1.13556 14.72 1.38663L9.00004 7.11996L3.28004 1.38663C3.02897 1.13556 2.68844 0.994507 2.33337 0.994507C1.97831 0.994507 1.63778 1.13556 1.38671 1.38663C1.13564 1.6377 0.994585 1.97823 0.994585 2.3333C0.994585 2.68836 1.13564 3.02889 1.38671 3.27996L7.12004 8.99996L1.38671 14.72C1.26174 14.8439 1.16254 14.9914 1.09485 15.1539C1.02716 15.3163 0.99231 15.4906 0.99231 15.6666C0.99231 15.8426 1.02716 16.0169 1.09485 16.1794C1.16254 16.3419 1.26174 16.4893 1.38671 16.6133C1.51066 16.7383 1.65813 16.8375 1.8206 16.9052C1.98308 16.9728 2.15736 17.0077 2.33337 17.0077C2.50939 17.0077 2.68366 16.9728 2.84614 16.9052C3.00862 16.8375 3.15609 16.7383 3.28004 16.6133L9.00004 10.88L14.72 16.6133C14.844 16.7383 14.9915 16.8375 15.1539 16.9052C15.3164 16.9728 15.4907 17.0077 15.6667 17.0077C15.8427 17.0077 16.017 16.9728 16.1795 16.9052C16.342 16.8375 16.4894 16.7383 16.6134 16.6133C16.7383 16.4893 16.8375 16.3419 16.9052 16.1794C16.9729 16.0169 17.0078 15.8426 17.0078 15.6666C17.0078 15.4906 16.9729 15.3163 16.9052 15.1539C16.8375 14.9914 16.7383 14.8439 16.6134 14.72L10.88 8.99996Z"
                  fill="black"
                />
              </svg>
            </span>
            <div className="profile_input">
              <label>*Old Password</label>
              <input
                type={showOldPassword ? "text" : "password"}
                className="form__control"
                spellCheck="false"
                placeholder="Enter Old Password"
                value={oldPassword}
                name="oldPassword"
                // style={{ color: editName ? "#000" : "#535353" }}
                // ref={textInput}
                onChange={(e) =>
                  e.target.value !== ""
                    ? setOldPassword(e.target.value)
                    : setOldPassword("")
                }
              />
              <span
                onClick={handleShowOldPassword}
                className="profile_showPassword"
              >
                <img src={eye} alt="eye" />
              </span>
            </div>
            <div className="profile_input">
              <label>*New Password</label>
              <input
                type={showNewPassword ? "text" : "password"}
                className="form__control"
                spellCheck="false"
                placeholder="Enter New Password"
                value={newPassword}
                name="newPassword"
                // style={{ color: editName ? "#000" : "#535353" }}
                // ref={textInput}
                onChange={(e) =>
                  e.target.value !== ""
                    ? setNewPassword(e.target.value)
                    : setNewPassword("")
                }
              />
              <span
                onClick={handleShowNewPassword}
                className="profile_showPassword"
              >
                <img src={eye} alt="eye" />
              </span>
            </div>
            <div className="profile_update_button">
              <button>{loading ? "Changing..." : "Change"}</button>
            </div>
            {/* {errorStatus && (
              <div className={errorStatus ? "error_wrapper" : ""}>
                <div
                  style={
                    errorStatus === false
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  *Email is Required
                </div>
              </div>
            )} */}
            <div
              className={errorStatus ? "error_wrapper" : ""}
              style={{ display: `${errorStatus ? "block" : "none"}` }}
            >
              <div
                style={
                  warningMsg_op === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **oldPassword is required
              </div>
              <div
                style={
                  warningMsg_np === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **newPassword is required
              </div>
              <div
                style={
                  warningMsg_le === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Password Length Should be 8 to 20 characters only
              </div>
              <div
                style={
                  warningMsg_spl === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Password Must contain any of the following symbols [@$!]
              </div>
              <div
                style={
                  warningMsg_num === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Password Must have one number in between[0-9]
              </div>
              <div
                style={
                  warningMsg_Up === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Password Must contain at least one upper-case
              </div>
              <div
                style={
                  warningMsg_lw === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Password Must contain at least one lower-case
              </div>
              <div
                style={
                  warningMsg_login === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Please Enter Valid Old Password!
              </div>
              {/* {error && <div className="error">{error}</div>} */}
            </div>
          </div>
        </form>
      )}

      <Footer />
    </div>
  );
}

export default Profile;
