import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Privacy.css";
export default function Privacy() {
  return (
    <div className="home">
      <Header />

      <div className="terms-wrapper ">
        <h5>Privacy Policy</h5>
        <div className="terms-card">
          <h4>Please read our policy carefully</h4>
          <p>
            Thanks for choosing degpeg (“ degpeg media “, “Service”, “we”, “us”,
            “our”, “degpeg media LLP ”). We are pleased to make our Service
            available to you. Degpeg provides Live streaming services, live
            shopping capabilities for enterprises, small &amp; medium businesses
            on web &amp; mobile platforms and all other related services from
            content ( video or audio ) creation or technology implementations.
            This is our Privacy Policy, which helps explain our information
            practices.
          </p>
          <p>
            If you install degpeg app or using our SAAS based platform or its
            features, you have read and accepted the terms of this Policy. You
            have also accepted the degpeg Terms and Conditions and Content
            Policy , Community Guidelines and other terms which are available on
            the degpeg App, degpeg Website, and otherwise. Please make sure to
            read these documents . By using degpeg, you are entering into a
            binding contract(s) with degpeg . Your contract with us includes
            this Policy, the Terms and Conditions, the Content Policy, etc. If
            you don’t agree with and accept the above, please do not use,
            install, access degpeg platform or any of its features.
          </p>
          <p>
            This Policy is applicable to information provided by users while
            using degpeg, and not to information made available to degpeg from
            others. Should degpeg obtain information pertaining to you from
            websites, apps, landing pages, third parties, etc. that may be
            accessed by degpeg users through degpeg or otherwise, such
            information will be subject to the privacy and other policies and
            terms of the said websites, apps, landing pages, etc. This Policy
            shall not apply to websites, apps, landing pages, etc. or any
            third-party services accessed by you through degpeg including in
            case such websites, apps, landing pages, etc. and/or third-party
            services are advertised on degpeg
          </p>
          <p>
            You also warrant and represent that any registration information and
            other information that you submit or otherwise provide to degpeg is
            true, accurate and complete, and you agree to keep it that way at
            all times.
          </p>
          <p>
            If you are subject to EU GDPR, you will be required to provide your
            explicit consent to the Privacy Policy by clicking on the ‘I Accept’
            button. Rights in addition to the below applicable to you are in
            Part II of this Privacy Policy.
          </p>
        </div>
        <div className="terms-card">
          <h4>Information We Collect</h4>
          <p>Information collected with a prompt:</p>
          <ul>
            <li>
              In order to use Degpeg, we may need you to authenticate your
              account using your Google Account, or otherwise, and we will be
              collecting your email address and name. We will also ask you to
              provide certain information including age, gender, telephone
              number, your content/category preferences, location, areas of
              interest, language, region preferences etc.
            </li>
            <li>
              We ask you for the location information and regional preferences
              to customize your experience by showing you relevant content.
              Degpeg may collect and store the current and past location of your
              device if you permit. You have full control over whether to allow
              or not allow degpeg to collect this information by making changes
              to your device’s settings. If degpeg is unable to access your
              location information, degpeg may become inaccessible or not
              function as intended.
            </li>
            <li>
              Customer Support. You may also provide us with information related
              to your use of degpeg , including copies of your messages / posts,
              and how to contact you so that we can provide you with customer
              support. For example, you may send us an email with information
              relating to our app/platform performance, or other issues.
            </li>
          </ul>
          <p>Information collected without a prompt:</p>
          <ul>
            <li>
              Device Information: We may collect the below with respect to any
              device through which degpeg is used or accessed.
            </li>
            <li>
              Information related to your use of degpeg such as content
              preferences and consumption, interaction with other users, user
              activity, etc.
            </li>
            <li>
              Degpeg may access your device’s camera, microphone, photo and/or
              video and/or audio library or gallery.
            </li>
            <li>
              Degpeg may also collect information provided by third parties who
              may disclose to Degpeg personal or non-personal information
              collected by them. Degpeg has no control over or responsibility in
              respect of third-party information practices or information
              collection methods.
            </li>
            <li>
              Degpeg may also collect tracking information including cookies,
              DART, beacons, etc.
            </li>
            <li>
              Location: Degpeg may collect and store the current and past
              location of your device if you permit. You have full control over
              whether to allow or not allow Degpeg to collect this information
              by making changes to your device’s settings. If degpeg is unable
              to access your location information, degpeg may become
              inaccessible or not function as intended.
            </li>
            <li>
              Usage and Log Information. We collect service-related, diagnostic,
              and performance information. This includes information about your
              activity (such as how you use degpeg , how you interact with
              others using degpeg , etc.), log files, and diagnostics, crash
              information, website, and performance logs and reports, etc.
            </li>
          </ul>
        </div>
        <div className="terms-card">
          <h4>Information We Do Not Collect</h4>
          <p>
            We do not collect any Sensitive Personal Data or information such as
            password related to other services, financial information such as
            bank account or credit card or debit card or other payment
            instrument details, physical, physiological and mental health
            condition, sexual orientation, medical records and history, or
            biometric information.
          </p>
        </div>
        <div className="terms-card">
          <h4>How We Use Information</h4>
          <p>
            We may use all the information we have to help us operate, provide,
            improve, understand, customize, support, and market Degpeg.
          </p>
          <ul>
            <li>
              Our Services: We operate and provide Degpeg, including providing
              customer support, and improving, fixing, and customizing degpeg by
              using the said information. We understand how users use degpeg,
              and analyze and use the information that we have to evaluate and
              improve degpeg, research, develop, and test new services and
              features, and conduct troubleshooting activities. We also use your
              information to respond to you when you contact us. We may also use
              your information for (i) displaying content based on interest,
              location, offers, etc., (ii) displaying or providing access to
              promotions, advertisements, offer, etc. which may be based on your
              interests and also ad-targeting, ad-placement, and ad-measurement
              (iii) displaying location specific news and weather related
              information, (iv) improving search results, content loading,
              performance, etc.
            </li>
            <li>Send you communications including by e-mail/sms.</li>
            <li>
              Safety and Security: We also use your information to try and
              ensure safety and security of our users and their information. We
              verify accounts and activity, and promote safety and security on
              and off Degpeg, such as by investigating suspicious activity or
              violations of our policies, terms, etc., and to try and ensure
              that degpeg is being used legally.
            </li>
          </ul>
        </div>
        <div className="terms-card">
          <h4>Managing Your Information</h4>
          <p>
            If you would like to manage, change, limit, or delete your
            information, we allow you to do that through the settings feature
            available on degpeg. You can change your settings to manage certain
            information available to other users. You can manage your contacts,
            groups, and broadcast lists, or use our block feature to manage the
            users with whom you communicate or who can communicate with you.
          </p>
        </div>
        <div className="terms-card">
          <h4>How We Share Information</h4>
          <p>We may share your information in ways including the following:</p>
          <ul>
            <li>
              With other degpeg users: your username, profile photograph,
              content that you post or send or share. You may also be able to
              control how your content is shared with other degpeg users by
              adjusting personal settings
            </li>
            <li>
              With our business partners, affiliates, investors: public
              information like your name, username and profile pictures and any
              content posted by you (subject to your personal settings).
            </li>
            <li>
              We may share information about you with third party service
              providers who perform services on our behalf, including to measure
              and optimize the performance of ads and deliver more relevant ads,
              including on third-party websites and apps.
            </li>
            <li>
              We may share information about you, such as device and usage
              information, to help us and others prevent fraud.
            </li>
            <li>
              We may share information about you for legal, safety, and security
              reasons.
            </li>
            <li>
              We may share information about you if we reasonably believe that
              disclosing the information is needed to: (i) comply with any valid
              legal process, governmental request, or applicable law, rule, or
              regulation, (ii) investigate, remedy, or enforce potential
              violations of this Policy, the Terms and Conditions and the
              Content Policy.
            </li>
          </ul>
        </div>
        <div className="terms-card">
          <h4>Security</h4>
          <p>
            We undertake no responsibility for security or safety of your
            information. However, we take reasonable security measures to
            protect your information in accordance with industry standards. This
            does not guarantee safety or security of your information as events
            beyond our control can also occur.
          </p>
        </div>
        <div className="terms-card">
          <h4>Assignment, Change Of Control, And Transfer</h4>
          <p>
            All of our rights and obligations under this Policy, the Terms and
            Conditions and the Content Policy are freely assignable by us to any
            of our affiliates or any others, in connection with a merger,
            acquisition, restructuring, or sale of assets, or by operation of
            law or otherwise, and we may transfer your information to any of our
            affiliates, any others successor entities, or new management, etc.
          </p>
        </div>
        <div className="terms-card">
          <h4>Law And Protection</h4>
          <p>
            We may collect, use, preserve, and share your information if we
            believe that it may be reasonably necessary to: (a) respond pursuant
            to applicable law or regulations, to legal process, or to government
            requests; (i) comply with any valid legal process, governmental
            request, or applicable law, rule, or regulation, (ii) investigate,
            remedy, or enforce potential violations of this Policy, the Terms
            and Conditions and Content Policy, (iii) to protect the rights,
            property, or safety of us, our users, or others, (iv) to detect and
            resolve any fraud or security concerns or technical issues, etc.,
            (iv) for other legal requirements, etc.
          </p>
        </div>
        <div className="terms-card">
          <h4>Updates To Our Policy</h4>
          <p>
            We may amend or update this Policy. We will provide you notice of
            amendments to this Policy, as appropriate, and update the “Last
            Modified” date at the top of this Policy. Your continued use of
            degpeg confirms your acceptance of this Policy, as amended. If you
            do not agree with or accept this Policy, as amended, you must stop
            using degpeg. Please review our Privacy Policy from time to time.
          </p>
        </div>
        <div className="terms-card">
          <h4>Contact Us</h4>
          <p>
            If you have questions about this Policy Email at:
            <a href="mailto:hello@degpeg.com">hello@degpeg.com</a>
          </p>
        </div>
        <div className="terms-card">
          <h4>Governing Law and Jurisdiction</h4>
          <p>
            The validity, construction and enforceability of these Terms and
            Content Policy, the Privacy Policy shall be governed and construed
            in accordance with the laws of India.
          </p>
          <p>
            Either party may refer such dispute for final resolution by
            arbitration under the Arbitration and Conciliation Act 1996. The
            Tribunal shall consist of three (3) arbitrators. Each party shall
            appoint 1 (one) arbitrator and the two arbitrators shall appoint the
            third arbitrator. The arbitration proceedings shall be held at
            Bangalore. The language to be used in the arbitral proceedings shall
            be English.
          </p>
        </div>
        <div className="terms-card">
          <h4>
            What are your rights under EU GDPR Law to the information we
            collect?
          </h4>
          <p>
            This is in addition to the above and in case of any conflict, the
            policies below shall be applicable if you are subject to EU GDPR.
          </p>
        </div>
        <div className="terms-card">
          <h4>Access to you data</h4>
          <p>
            You have the right to your personal data including the content you
            upload. You can access your information by logging into your
            account. You can email us at hello@degpeg.com with your registered
            email address. Please provide us a month to respond with the
            information and two months if the request is too complicated to
            fulfil within a month.
          </p>
        </div>
        <div className="terms-card">
          <h4>Withdrawal of consent</h4>
          <p>
            You also have the right to withdraw your consent at any time and
            request us to stop processing your data. However, withdrawing your
            consent will not affect the processing or use of your information
            prior to fulfilling your consent withdrawal request. You can email
            us hello@degpeg.com with your registered email address.
          </p>
        </div>
        <div className="terms-card">
          <h4>Request for deletion</h4>
          <p>
            You can email us hello@degpeg.com with your registered email address
            and request for your deletion of data .
          </p>
          <p>
            We store data for as long as it is necessary to provide products and
            services to you and others. Information associated with your account
            will be kept until your account is deleted, unless we longer need
            the data to provide the products and services or until we are
            legally required to do so.
          </p>
        </div>
        <div className="terms-card">
          <h4>Request to correct your data</h4>
          <p>
            In case you find any of your information to be inaccurate or
            incorrect in any way, you can write to us at hello@degpeg.com with
            your registered email and we will take reasonable steps to update or
            rectify your information.
          </p>
        </div>
        <div className="terms-card">
          <h4>Complaints and grievances</h4>
          <p>
            With respect to collection and use of your personal data you have
            the right to complain to the local Data Protection Authority. You
            can email us hello@degpeg.com with your registered email address
          </p>
        </div>
        <div className="terms-card">
          <h4>Third party Links</h4>
          <p>
            We are not responsible for any content nor any consequences either
            directly or indirectly arising out of clicking on any third party
            link you may come across posted by other users. We may share your
            information with approved third parties in order to provide our
            services through the degpeg App
          </p>
        </div>
        <div className="terms-card">
          <h4>Data Transfers</h4>
          <p>
            We may transfer personal information to countries other than the
            country in which the data was originally collected.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
