import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import PlayCard from "../PlayCard/PlayCard";
import PlayCardHeading from "../PlayCard/PlayCardHeading";
import {
  //   getAllSessionsForcontentProvider,
  // getFilteredCategories
  // getAllSessions
  getAllSessionsForcontentProvider
} from "../../components/API/Api";
import saly18 from "../../assets/img/event/Saly-18.png";
import arrowbottom from "../../assets/img/arrowbottom.svg";
function WishList() {
  const [data, setdata] = useState([]);
  const [FilteredCategories] = useState(getAllSessionsForcontentProvider());
  const [newList, setNewList] = useState(
    localStorage.getItem("wishList")
      ? JSON.parse(localStorage.getItem("wishList"))
      : []
  );
  const [showMore, setShowMore] = useState(false);
  const numberOfItems = showMore ? newList.length : 8;
  useEffect(() => {
    window.scrollTo(0, 0);

    FilteredCategories.then(function (result) {
      setdata(result);
    });
  }, [FilteredCategories]);

  return (
    <div className="home">
      <Header />
      <main style={{ marginTop: "90px" }}>
        {/* {newList.length !== 0 && ( */}
        <section className="common__section" id="wishList">
          <div className="container">
            <PlayCardHeading title="" titleHighlight="Favorites" />

            <div
              style={{
                display: "flex",
                // justifyContent: "space-between",
                flex: "4",
                flexWrap: "wrap"
                // padding: "20px",
                // margin: "1% 0%"
              }}
            >
              {newList.length !== 0 ? (
                data.map((data, id) => {
                  return newList.slice(0, numberOfItems).map((list, index) => {
                    return (
                      data.id === list && (
                        <div
                          style={{
                            marginRight: "20px",
                            // marginLeft: "20px",
                            marginBottom: "70px",
                            width: "300px"
                          }}
                          key={index}
                        >
                          <PlayCard
                            key={data.id}
                            videoid={data.id}
                            titleVideo={data.web_video_url}
                            titleImg={data.banner_url}
                            titleDesc={data.description}
                            titleName={data.name}
                            tags={data.tags}
                            icon={true}
                            status={data.status}
                            removeWishList={() => {
                              const s = newList.filter(
                                (item) => item !== data.id
                              );
                              setNewList(s);
                              localStorage.setItem(
                                "wishList",
                                JSON.stringify(s)
                              );
                            }}
                          />
                        </div>
                        // ) : (
                        //   ""
                      )
                    );
                  });
                })
              ) : (
                <div
                  style={{
                    // marginRight: "20px",
                    // marginLeft: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                    fontSize: "24px"
                  }}
                  // key={id}
                >
                  <p>You Are not added any favourites !</p>
                  <img src={saly18} alt="no Data" />
                </div>
              )}
            </div>
            {newList.length > 8 && (
              <div
                className={`showaction ${showMore ? "showless" : "showmore"}`}
              >
                <span onClick={() => setShowMore(!showMore)}>
                  {showMore ? "Show less" : "Show more"}
                  <img src={arrowbottom} alt="show" />
                </span>
              </div>
            )}
          </div>
        </section>
        {/* )} */}
      </main>
      <Footer />
    </div>
  );
}

export default WishList;
