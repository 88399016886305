import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  getAllSessionsForcontentProvider,
  getAllSessionsOfContentProvider,
  getCta,
  getProdct
} from "../../components/API/Api";
import { useParams } from "react-router-dom";
import Chat from "./Chat/index";
import Cta from "./Cta/index";
import VideoPlayerHeader from "./VideoHeader/index";
import VideoPlayer from "./VideoPlayer/index";
import comingsoonBanner from "../../assets/img/newTemplate/default_thumbnail.png";
import plannedImg from "../../assets/img/newTemplate/planned.svg";
import errorpage from "../../assets/img/newTemplate/errorpage.jpg";
import PlayCardHeading from "../PlayCard/PlayCardHeading";
import Slider from "react-slick";
import PlayCard from "../PlayCard/PlayCard";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const Dashboard = (props) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [landScape, setLandScape] = useState(2);
  const [minimize, setMinimize] = useState(false);
  const [data, setData] = useState(null);
  const [product, setProduct] = useState([]);
  const [cta, setCta] = useState([]);
  const [allVedios, setAllvedios] = useState([]);
  const [distanceTime, setDistance] = useState("");
  const { id } = useParams();
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [setContentProviderId] = useState(props.location.contentProviderId);
  const [sessionsForcontentProvider] = useState(
    getAllSessionsForcontentProvider()
  );
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5.4,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          draggable: true,
          swipeToSlide: true,
          touchMove: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          draggable: true,
          swipeToSlide: true,
          touchMove: true
          // arrows: false,
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
          draggable: true,
          swipeToSlide: true,
          touchMove: true
          // arrows: false,
        }
      }
    ]
  };
  // toggle chat
  const toggleChat = () => {
    setMinimize(!minimize);
    if (landScape === 1) {
      setLandScape(2);
      setMinimize(false);
    }
  };
  // toggle cta
  const toggleCta = () => {
    setMinimize(!minimize);
    if (landScape === 2) {
      setLandScape(1);
      setMinimize(false);
    }
  };
  // toggle minize
  const toggleMinimize = () => {
    if (!minimize) {
      setMinimize(true);
    } else {
      setMinimize(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    sessionsForcontentProvider.then((res) => {
      console.log(res);

      if (res) {
        res.sort(function (o1, o2) {
          // return o1.date_time ? -1 : o2.date_time ? 1 : 0;
          return new Date(o2.date_time) - new Date(o1.date_time);
        });
        console.log(res);
        setAllvedios(res);
        // setRelatedVideos(res);

        res.map((item) => {
          if (item.id === id) {
            getAllSessionsOfContentProvider(item.contentProviderId).then(
              (rese) => {
                // console.log("relatedVideos", rese, item.contentProviderId);
                rese.sort(function (o1, o2) {
                  // return o1.date_time ? -1 : o2.date_time ? 1 : 0;
                  return new Date(o2.date_time) - new Date(o1.date_time);
                });
                setRelatedVideos(rese);
              }
            );
          }
          return 0;
        });
        setData(res);
        res.map((item) => {
          if (item.id === id) {
            // setContentProviderId(item.contentProviderId);
            // console.log("video", item);
            if (item.status === "scheduled" || item.status === "planned") {
              setData(item);
              timer(item);
              item.products &&
                item.products.map((item) => {
                  getProdct(item).then((data) => {
                    // console.log(data);
                    setProduct([...product, data.data[0]]);
                  });
                  return 0;
                });
              item.ctaIds &&
                item.ctaIds.map((item) => {
                  getCta(item).then((data) => {
                    setCta([...cta, data.data[0]]);
                  });
                  return 0;
                });
            } else if (item.status === "live") {
              setData(item);
              item.products &&
                item.products.map((item) => {
                  getProdct(item).then((data) => {
                    // console.log(data);
                    setProduct([...product, data.data[0]]);
                  });
                  return 0;
                });
              item.ctaIds &&
                item.ctaIds.map((item) => {
                  getCta(item).then((data) => {
                    setCta([...cta, data.data[0]]);
                  });
                  return 0;
                });
            } else {
              setData(item);
              console.log("sssss", item);
              item.products &&
                item.products.map((item) => {
                  getProdct(item).then((data) => {
                    // console.log(data);
                    setProduct([...product, data.data[0]]);
                  });
                  return 0;
                });
              item.ctaIds &&
                item.ctaIds.map((item) => {
                  getCta(item).then((data) => {
                    setCta([...cta, data.data[0]]);
                  });
                  return 0;
                });
            }
          }
          return 0;
        });
      } else {
        setData(null);
      }
    });

    // eslint-disable-next-line
  }, [
    setData,
    id,
    setCta,
    setProduct,
    sessionsForcontentProvider,
    setContentProviderId
  ]);

  const timer = (data) => {
    var countDownDate = new Date(data.date_time).getTime();
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      // console.log("timer", distance);
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      // console.log(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");
      setDistance(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");
      if (distance < 0) {
        clearInterval(x);
        setDistance("");
        window.location.reload();
      } else if (distance === 0) {
        window.location.reload();
      }
    }, 1000);
  };
  const dragFullScreen = () => {
    let vid = document.getElementById("fullscreen") || document.body;
    if (!fullScreen && !window.screen.orientation.angle) {
      alert("please rotate your screen for better view");
      // setFullScreen(true);
    }
    setFullScreen(!fullScreen);

    vid.addEventListener("touchstart", handleTouchStart, false);
    vid.addEventListener("touchmove", handleTouchMove, false);

    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
      return (
        evt.touches || // browser API
        evt.originalEvent.touches
      ); // jQuery
    }

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (Math.abs(xDiff) < Math.abs(yDiff)) {
        /*most significant*/
        if (yDiff < -20) {
          /* down swipe */

          // window.exitFullScreen();
          setFullScreen(false);
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    }
  };
  const launchFullScreen = () => {
    if (!window.isiPhone) {
      if (window.isInFullScreen()) {
        window.exitFullScreen();
        setFullScreen(false);
      } else {
        window.requestFullScreen(
          document.getElementById("fullscreen") || document.body
        );
        setFullScreen(true);
      }
    } else {
      dragFullScreen();
    }

    document.onfullscreenchange = function () {
      const element = document.fullscreenElement;
      if (element) {
        setFullScreen(true);
        console.log("Enter Full Screen Event occurred", element);
      } else {
        console.log("Exit Full Screen Event occurred", element);
        setFullScreen(false);
      }
    };
  };

  return (
    <div className="home">
      <Header />
      {data !== null ? (
        data !== "coming" ? (
          <main className="main__wrapper">
            <div className="container">
              <div
                className={`${
                  !fullScreen
                    ? "template__container"
                    : "template__container fullscreen-mode"
                }`}
              >
                {allVedios.map((data) => {
                  if (data.id === id) {
                    return (
                      <div className="template__sdk" key={data.id}>
                        {data.status !== "planned" ||
                        data.status !== "scheduled" ? (
                          <div
                            className={`${
                              !fullScreen
                                ? "template__wrapper "
                                : "template__wrapper fullscreen-video"
                            }`}
                            id="fullscreen"
                          >
                            <VideoPlayer
                              poster={
                                data.web_banner_url
                                  ? data.web_banner_url
                                  : comingsoonBanner
                              }
                              session_id={data.id}
                              dateTime={data.date_time}
                              status={data.status}
                              videoUrl={data.web_video_url}
                              launchFullScreen={launchFullScreen}
                              // dragFullScreen={dragFullScreen}
                              fullScreen={fullScreen}
                              toggleChat={toggleChat}
                              toggleCta={toggleCta}
                              cta={cta}
                              product={product}
                              data={data}
                              contentProviderId={data.contentProviderId}
                            >
                              {data.status !== "planned" &&
                                data.status !== "scheduled" && (
                                  <Chat
                                    session_id={data.id}
                                    status={data.status}
                                    landScape={landScape}
                                    minimize={minimize}
                                  />
                                )}
                            </VideoPlayer>
                            <Cta
                              session_id={data.id}
                              landScape={landScape}
                              minimize={minimize}
                              toggleMinimize={toggleMinimize}
                              status={data.status}
                              contentProviderId={data.contentProviderId}
                            />
                          </div>
                        ) : (
                          <div className="planned_timer_wrapper">
                            <div className="planned_timer">
                              Staring In
                              <br />
                              {distanceTime}
                            </div>
                            <img src={plannedImg} alt="planned" />
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
                {data.contentProviderId && (
                  <VideoPlayerHeader
                    dateTime={data.date_time}
                    status={data.status}
                    name={data.name}
                    description={data.description}
                    contentProviderId={data.contentProviderId}
                  />
                )}

                {data.status !== "planned" && data.status !== "scheduled" && (
                  <div className="portrait-show">
                    <Tabs className="common-tabs">
                      <TabList>
                        {(product.length > 0 || cta.length > 0) && (
                          <Tab>Products</Tab>
                        )}
                        <Tab>Chat </Tab>
                      </TabList>
                      <div className="common-tab-panel">
                        {(product.length > 0 || cta.length > 0) && (
                          <TabPanel>
                            <Cta
                              session_id={data.id}
                              status={data.status}
                              contentProviderId={data.contentProviderId}
                            />
                          </TabPanel>
                        )}
                        <TabPanel>
                          <Chat session_id={data.id} status={data.status} />
                        </TabPanel>
                      </div>
                    </Tabs>
                  </div>
                )}
              </div>
            </div>
          </main>
        ) : (
          <main className="main__wrapper">
            <img src={errorpage} alt="coming soon" />
          </main>
        )
      ) : (
        <main className="main__wrapper">
          <img src={comingsoonBanner} alt="error" />
        </main>
      )}

      {relatedVideos.length > 0 ? (
        <section
          className="common__section grey-section"
          style={{ paddingBottom: "140px" }}
        >
          <div className="container">
            <PlayCardHeading title="Related" titleHighlight="Videos" />
            {/* </div> */}
            <div className="common__slider">
              {relatedVideos.length > 0 ? (
                <Slider {...settings}>
                  {relatedVideos.map((data, index) => {
                    if (
                      data.web_video_url &&
                      ((data.status === "completed" && data.web_video_url) ||
                        (Math.round(
                          (new Date() - new Date(data.date_time)) / 1000 / 60
                        ) < 20 &&
                          (data.status === "live" ||
                            data.status === "planned" ||
                            data.status === "scheduled")))
                    ) {
                      return (
                        <PlayCard
                          key={index}
                          videoid={data.id}
                          titleVideo={data.web_video_url}
                          titleImg={data.banner_url}
                          titleDesc={data.description}
                          titleName={data.name}
                          tags={data.tags}
                          status={data.status}
                          dateTime={data.date_time}
                          contentProviderId={data.contentProviderId}
                        />
                      );
                    }
                    return 0;
                  })}
                </Slider>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
};

export default Dashboard;
