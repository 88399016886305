import React from "react";

const PlayCardHeading = ({ title, titleHighlight, white }) => {
  return (
    <div className={`common-heading ${white ? "white" : ""}`}>
      <h3>
        {title}
        <span> {titleHighlight} </span>
      </h3>
    </div>
  );
};

export default React.memo(PlayCardHeading);
