import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// images
import logoIcon from "../../assets/img/logo.svg";
import loginImg from "../../assets/img/reset-password.png";
import signupImg from "../../assets/img/reset-password2.png";
import blackLogo from "../../assets/img/event/black_logo.png";
import eye from "../../assets/img/Eye.svg";

import "../../assets/styles/common/reset.css";
import { patchGenerateNewPassword } from "../API/Api";
import { Slide, toast, ToastContainer } from "react-toastify";
const Reset = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [warningMsg_login, setWarningMsg_login] = useState(false);

  const [warningMsg_p, setWarningMsg_p] = useState(false);
  const [warningMsg_le, setWarningMsg_le] = useState(false);
  const [warningMsg_Up, setWarningMsg_Up] = useState(false);
  const [warningMsg_lw, setWarningMsg_lw] = useState(false);
  const [warningMsg_spl, setWarningMsg_spl] = useState(false);
  const [warningMsg_num, setWarningMsg_num] = useState(false);
  const [erroStatus, setErrorStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState(null);

  const searchParams = new URLSearchParams(window.location.search);
  const history = useHistory();

  function changePassword(e) {
    e.preventDefault();
    setError(null);
    setErrorStatus(true);
    if (password === "") {
      return setWarningMsg_p(true);
    } else {
      setWarningMsg_p(false);
    }
    if (new RegExp("(?=.*[a-z])").test(password)) {
      setWarningMsg_lw(false);
    } else {
      return setWarningMsg_lw(true);
    }
    if (new RegExp("(?=.*[A-Z])").test(password.trim())) {
      setWarningMsg_Up(false);
    } else {
      return setWarningMsg_Up(true);
    }
    if (new RegExp("(?=.*[0-9])").test(password.trim())) {
      setWarningMsg_num(false);
    } else {
      return setWarningMsg_num(true);
    }
    if (new RegExp("(?=.*[@$!])").test(password.trim())) {
      setWarningMsg_spl(false);
    } else {
      return setWarningMsg_spl(true);
    }
    if (new RegExp("(?=.{8,})").test(password.trim())) {
      setWarningMsg_le(false);
    } else {
      return setWarningMsg_le(true);
    }

    if (password !== newPassword) {
      setWarningMsg_login(true);
    } else {
      // alert("success");
      setErrorStatus(false);
      const obj = {
        userId: `${searchParams.get("userId")}`,
        password: `${newPassword.trim()}`
      };

      patchGenerateNewPassword(searchParams, obj)
        .then((response) => {
          setErrorStatus(false);

          history.push("/");
          showResetToast();
        })
        .catch((error) => {
          setError(error);
        });
    }
  }
  const showResetToast = () => {
    toast.error("Password changed Successfully Please login Again!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000
    });
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="reset_container">
      <div className="reset_Wrapper">
        <div className="reset_left">
          <div className="reset__logo">
            <img src={blackLogo} alt="logo" />
            {/* <span>Live Commerce Platform</span> */}
          </div>
          <ToastContainer
            limit={1}
            transition={Slide}
            style={{ fontSize: "16px" }}
          />
          <form onSubmit={changePassword}>
            <h5 className="reset_header">Choose a New Password</h5>

            <div className="reset_form__group">
              <label>New Password</label>
              <input
                type="text"
                spellCheck="false"
                className="form__control"
                placeholder="Password"
                value={password}
                onChange={(e) =>
                  e.target.value !== ""
                    ? setPassword(e.target.value)
                    : setPassword(e.target.value)
                }
              />{" "}
            </div>
            <div className="reset_form__group">
              <label>Repeat your New Password</label>
              <input
                type={showPassword ? "text" : "password"}
                spellCheck="false"
                className="form__control"
                placeholder="Repeat your New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <span onClick={handleShowPassword} className="showPassword">
                <img src={eye} alt="eye" />
              </span>
            </div>
            <button className="rest_buttun">Change Password</button>

            <div className={erroStatus ? "error_wrapper" : ""}>
              <div
                style={
                  warningMsg_p === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Password is required
              </div>
              <div
                style={
                  warningMsg_le === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Password Length Should be 8 to 20 characters only
              </div>
              <div
                style={
                  warningMsg_spl === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Password Must contain any of the following symbols [@$!]
              </div>
              <div
                style={
                  warningMsg_num === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Password Must have one number in between[0-9]
              </div>
              <div
                style={
                  warningMsg_Up === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Password Must contain at least one upper-case
              </div>
              <div
                style={
                  warningMsg_lw === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Password Must contain at least one lower-case
              </div>
              <div
                style={
                  warningMsg_login === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                **Passwords mismatch!
              </div>
              {error && <div className="error">{error}</div>}
            </div>
          </form>
        </div>
        <div className="reset_right">
          <div className="reset_icon">
            <div className="box_image">
              <img src={loginImg} alt="icon" />
              <img src={logoIcon} alt="logo" className="center_logo" />
            </div>
            <div className="circle_image">
              <img src={signupImg} alt="icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
