import React, { Component } from "react";

import imgeLogo from "../../../assets/img/newTemplate/profilelogo.png";

import { getContentProviderUserDetails } from "../../../components/API/Api";
export default class VideoPlayerHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      show_message: "",
      chat: [],
      post: [],
      name: "",
      description: "",
      contentProviderName: "",
      contentProviderUrl: ""
    };
  }
  componentDidMount() {
    getContentProviderUserDetails(this.props.contentProviderId).then((res) => {
      console.log("content-Provider", res);
      this.setState({
        contentProviderName: res.data[0].firstName
          ? res.data[0].firstName
          : res.data[0].email.split("@")[0],
        contentProviderUrl: res.data[0].displayPicture
          ? res.data[0].displayPicture
          : imgeLogo
      });
    });
  }

  render(props) {
    return (
      <div className="videoplayer__header">
        <div className="videoplayer__profile">
          <div className="videoplayer__profile-icon">
            <img src={this.state.contentProviderUrl} alt="banner icon" />
          </div>
          <div className="videoplayer__profile-desc">
            <h4>{this.state.contentProviderName}</h4>
            <span>24k Subscribers</span>
          </div>
        </div>
        <div className="videoplayer__desc">
          <h5>{this.props.name}</h5>
          <p>{this.props.description}</p>
        </div>
      </div>
    );
  }
}
