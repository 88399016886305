import React, { Component } from "react";

import {
  getAllProdcts,
  getCta,
  getProdct,
  getPurchaseCount,
  postPurchases,
  getShopifyProductsBySessionId,
  getAllContentProviders,
  getShopDetails,
  getProductsByShopName
} from "../../API/Api";

import carticon from "../../../assets/img/newTemplate/carticon.svg";
import downArrow from "../../../assets/img/newTemplate/down-arrow.svg";

export default class Cta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
      cta: [],
      minimize: false,
      shopifyProductIds: [],
      shopName: "",
      shopifyAllProducts: [],
      // shopifyData: [],
      shopifyTest: []
    };
  }
  componentDidMount() {
    console.log(this.props);
    if (this.props.session_id) {
      getAllProdcts(this.props.session_id, this.props.contentProviderId).then(
        (response) => {
          console.log("session", response);
          if (response.data[0].products) {
            response.data[0].products.map((item) => {
              getProdct(item).then((data) => {
                console.log(data);
                this.setState({
                  product: [...this.state.product, data.data[0]]
                });
              });
              return 0;
            });
          }
          if (response.data[0].ctaIds) {
            response.data[0].ctaIds.map((item) => {
              getCta(item).then((data) => {
                this.setState({
                  cta: [...this.state.cta, data.data[0]]
                });
              });
              return 0;
            });
          }
          return 0;
        }
      );
      getShopifyProductsBySessionId(this.props.session_id).then((response) => {
        //sessionId
        response.data.forEach((element) => {
          // this.state.shopifyProductIds.push(element.productId);
          this.setState({
            shopifyProductIds: [
              ...this.state.shopifyProductIds,
              element.productId
            ]
          });
        });
        getAllContentProviders().then((userDeatails) => {
          //contentprovider
          getShopDetails(userDeatails.data[0].id).then((shop) => {
            // this.state.shopName = shop.data[0].shopName;
            if (shop.data.length > 0) {
              this.setState({ shopName: shop.data[0].shopName });

              getProductsByShopName(shop.data[0].shopName).then((res) => {
                this.setState({ shopifyAllProducts: res.data.body.products });
                console.log("Checking type", res.data.body.products);
                this.state.shopifyProductIds.forEach((product) => {
                  res.data.body.products.map((item) => {
                    if (item.id.toString() === product.toString()) {
                      this.setState({
                        shopifyTest: [...this.state.shopifyTest, item]
                      });
                      console.log("test", item);
                    }
                    return 0;
                  });
                });
              });
            }
          });
        });
      });
      console.log("shopify data", this.shopifyTest instanceof Array);
      console.log(this.shopifyTest);
      console.log(this.state.cta);
    }
  }
  purchaseUpdateForProduct = () => {
    const now = new Date();
    const dt = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();
    var msg = {
      liveSessionId: this.props.session_id,
      time_stamp: dt,
      amount: "1"
    };
    console.log(msg);
    postPurchases(msg).then((res) => {
      getPurchaseCount(this.props.session_id).then((res) =>
        console.log("Purchases_count", res)
      );
    });
  };
  purchaseUpdateForCta = () => {
    const now = new Date();
    const dt = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();
    var msg = {
      liveSessionId: this.props.session_id,
      time_stamp: dt,
      amount: "0"
    };
    console.log(msg);
    postPurchases(msg).then((res) => {
      getPurchaseCount(this.props.session_id).then((res) =>
        console.log("Purchases_count", res)
      );
    });
  };
  minimizebtn = () => {
    this.props.toggleMinimize();
  };
  render() {
    return (
      <div
        className={`cta__wrapper ${this.props.minimize ? "cta__close" : ""}`}
      >
        <div className="m-btn-minimize m-show" onClick={this.minimizebtn}>
          <img src={downArrow} alt="down-arrow" />
        </div>
        {/* desktop show */}
        {this.state.shopName ||
        this.state.product.length > 0 ||
        this.state.cta.length > 0 ? (
          <div className="cta__detailwrap m-hide">
            {/*Shopify product fetching card */}

            {this.state.shopName
              ? this.state.shopifyTest.map((product, index) => (
                  <div className="cta__card" key={index}>
                    <h4>{product.variants[0].price}</h4>
                    <div className="cta__img">
                      <img src={product.image.src} alt="img" />
                    </div>
                    <a
                      href={
                        "https://" +
                        this.state.shopName +
                        "/products/" +
                        product.handle
                      }
                      target="_blank"
                      className="btn btn-primary"
                      rel="noreferrer"
                      onClick={this.purchaseUpdateForProduct}
                    >
                      Buy Now
                    </a>
                  </div>
                ))
              : ""}
            {/* product fetching card */}
            {this.state.product.map((products) => (
              <div className="cta__card" key={products.id}>
                <h4>
                  {products.currency} {products.price}
                </h4>
                <div className="cta__img">
                  <img src={products.image_url} alt="img" />
                </div>
                {products.purchase_link.includes("https://") ? (
                  <a
                    href={products.purchase_link}
                    target="_blank"
                    className="btn btn-primary"
                    rel="noreferrer"
                    onClick={this.purchaseUpdateForProduct}
                  >
                    Buy Now
                  </a>
                ) : (
                  <a
                    href={"https://" + products.purchase_link}
                    target="_blank"
                    className="btn btn-primary"
                    rel="noreferrer"
                    onClick={this.purchaseUpdateForProduct}
                  >
                    Buy Now
                  </a>
                )}
              </div>
            ))}
            {/* product fetching card */}
            {this.state.cta.map((ctas) => (
              <div className="cta__card" key={ctas.id}>
                <h4>{ctas.name}</h4>
                <div className="cta__img">
                  <img src={ctas.filepath} alt="img" />
                </div>
                {ctas.ctaLink.includes("https://") ? (
                  <a
                    href={ctas.ctaLink}
                    target="_blank"
                    className="btn btn-primary"
                    rel="noreferrer"
                    onClick={this.purchaseUpdateForCta}
                  >
                    Click Here
                  </a>
                ) : (
                  <a
                    href={"https://" + ctas.ctaLink}
                    target="_blank"
                    className="btn btn-primary"
                    rel="noreferrer"
                    onClick={this.purchaseUpdateForCta}
                  >
                    Click Here
                  </a>
                )}
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
        {/* mobile show */}
        <div className="m-show">
          <div
            className={`cta__detailwrap  ${
              this.props.landScape === 1 ? "active" : "inactive"
            }`}
          >
            {/* Shopify import */}
            {this.state.shopifyTest.length > 0 &&
              this.state.shopifyTest.map((product, index) => (
                <div className="cta__card" key={index}>
                  <div className="cta__img">
                    <img src={product.image.src} alt="img" />
                  </div>
                  <div className="cta__desc">
                    <h5>{product.title}</h5>
                    <h4>{product.variants[0].price}</h4>
                    <a
                      href={
                        "https://" +
                        this.state.shopName +
                        "/products/" +
                        product.handle
                      }
                      target="_blank"
                      className="btn btn-primary"
                      rel="noreferrer"
                      onClick={this.purchaseUpdateForProduct}
                    >
                      <img src={carticon} alt="cart" /> Buy Now
                    </a>
                  </div>
                </div>
              ))}

            {/* product fetching card */}
            {this.state.product.length > 0 &&
              this.state.product.map((products) => (
                <div className="cta__card" key={products.id}>
                  <div className="cta__img">
                    <img src={products.image_url} alt="img" />
                  </div>
                  <div className="cta__desc">
                    <h5>{products.name}</h5>
                    <h4>
                      {products.currency} {products.price}
                    </h4>
                    {products.purchase_link.includes("https://") ? (
                      <a
                        href={products.purchase_link}
                        target="_blank"
                        className="btn btn-primary"
                        rel="noreferrer"
                        onClick={this.purchaseUpdateForProduct}
                      >
                        <img src={carticon} alt="cart" /> Buy Now
                      </a>
                    ) : (
                      <a
                        href={"https://" + products.purchase_link}
                        target="_blank"
                        className="btn btn-primary"
                        rel="noreferrer"
                        onClick={this.purchaseUpdateForProduct}
                      >
                        <img src={carticon} alt="cart" /> Buy Now
                      </a>
                    )}
                  </div>
                </div>
              ))}
            {/* product fetching card */}
            {this.state.cta.length > 0 &&
              this.state.cta.map((ctas) => (
                <div className="cta__card" key={ctas.id}>
                  <div className="cta__img">
                    <img src={ctas.filepath} alt="img" />
                  </div>
                  <div className="cta__desc">
                    <h5>{ctas.name}</h5>
                    {ctas.ctaLink.includes("https://") ? (
                      <a
                        href={ctas.ctaLink}
                        target="_blank"
                        className="btn btn-primary"
                        rel="noreferrer"
                        onClick={this.purchaseUpdateForCta}
                      >
                        Click Here
                      </a>
                    ) : (
                      <a
                        href={"https://" + ctas.ctaLink}
                        target="_blank"
                        className="btn btn-primary"
                        rel="noreferrer"
                        onClick={this.purchaseUpdateForCta}
                      >
                        Click Here
                      </a>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
